import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Import auth and Firestore db
import { doc, getDoc } from 'firebase/firestore'; // Firestore-specific imports

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        try {
          // Fetch the user's document from Firestore
          const userDocRef = doc(db, 'users', authUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            // Check if the user has an admin role from Firestore
            setIsAdmin(userData.role === 'admin');
          } else {
            console.log('No user document found in Firestore');
          }
        } catch (error) {
          console.error('Error fetching user document:', error);
        }
        setUser(authUser);
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // If the user is authenticated and is an admin, render the protected content
  if (user && isAdmin) {
    return children;
  }

  // Redirect to login if not authenticated or not an admin
  return <Navigate to="/login" />;
};

export default ProtectedRoute;
