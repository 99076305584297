import React from 'react';
import './TcInstaBlitzDriver.css'; // Optional: Add custom styling for your terms page

const TcInstaBlitzDriver = () => {
  return (
    <div className="tc-page">
      <h1>InstaBlitz Driver Terms and Conditions</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>
      <section className="tc-content">
        <p>
          These terms and conditions ("Agreement") govern your participation as a driver in the InstaBlitz service, a feature provided by [Your Company Name] ("Company"). By accessing or using the InstaBlitz feature, you ("Driver" or "you") agree to the terms set forth below. If you do not agree to these terms, you may not use the InstaBlitz feature.
        </p>

        <h2>1. Eligibility and Compliance</h2>
        <p><strong>Eligibility:</strong> To be eligible to participate as a driver in the InstaBlitz feature, you must:</p>
        <ul>
          <li>Hold a valid driver’s license.</li>
          <li>Be at least 21 years old.</li>
          <li>Provide accurate and truthful information, including your name, email, and verified phone number.</li>
        </ul>
        <p>
          <strong>License Verification:</strong> You agree to allow [Company] to verify your driver’s license using available technology, such as the Google Vision API, to ensure that the license is valid and not expired. You acknowledge that providing fraudulent or expired license information may result in the termination of your access to InstaBlitz.
        </p>

        <h2>2. Insurance Requirements</h2>
        <p>
          <strong>Personal Insurance:</strong> You are required to have valid and up-to-date personal auto insurance to participate in InstaBlitz rides. You represent and warrant that you carry the necessary insurance as required by law for your vehicle.
        </p>
        <p>
          <strong>No Commercial Insurance Requirement:</strong> The Company does not require drivers to carry commercial insurance for InstaBlitz rides, as they are considered informal and on-demand. You acknowledge that your personal insurance may not cover all liabilities that arise from offering rides through InstaBlitz, and you assume full responsibility for any claims that arise.
        </p>

        <h2>3. Payment and Debit Card Verification</h2>
        <p>
          <strong>Debit Card:</strong> You agree to provide a valid debit card to receive payments for rides conducted through InstaBlitz. By submitting your card details, you consent to the Company verifying the card to ensure it is active and tied to your identity.
        </p>
        <p>
          <strong>Payouts:</strong> Payments for InstaBlitz rides will be made directly to your verified debit card. The Company is not responsible for any delays or errors caused by incorrect or invalid card information.
        </p>

        <h2>4. Rides and Driver Responsibilities</h2>
        <p>
          <strong>On-Demand Service:</strong> InstaBlitz is an on-demand, spontaneous ride service, where riders may approach you directly for immediate rides without prior bookings. You are responsible for ensuring that your vehicle is roadworthy and safe for transportation before offering any rides.
        </p>
        <p>
          <strong>One-Time Ride Limitation:</strong> InstaBlitz allows drivers to provide spur-of-the-moment rides without full onboarding. However, if you choose to offer more than one ride, you may be required to complete the full onboarding process, which includes submitting additional documentation such as proof of insurance, background checks, and other compliance requirements.
        </p>
        <p>
          <strong>Rider Safety:</strong> You are responsible for the safety and well-being of any riders during the ride. You agree to comply with all traffic laws and ensure that your vehicle is safe for operation.
        </p>

        <h2>5. Background Checks and Additional Requirements</h2>
        <p>
          <strong>No Immediate Background Check:</strong> InstaBlitz does not require immediate background checks for single-use or one-off rides. However, the Company reserves the right to perform a background check if you offer multiple rides or choose to join the regular driver pool.
        </p>
        <p>
          <strong>Regular Driver Pool:</strong> If you intend to offer recurring rides or join the regular driver pool, you must complete the full onboarding process, including the submission of additional documents and the completion of a background check.
        </p>

        <h2>6. Liability and Disclaimer</h2>
        <p>
          <strong>Assumption of Risk:</strong> You acknowledge that offering rides through InstaBlitz may involve inherent risks, including but not limited to accidents, damage to your vehicle, or disputes with riders. You agree to assume all such risks and release the Company from any liability that may arise.
        </p>
        <p>
          <strong>Indemnification:</strong> You agree to indemnify, defend, and hold harmless the Company, its officers, directors, employees, and agents, from and against any and all claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected to your participation as a driver in InstaBlitz.
        </p>

        <h2>7. Termination</h2>
        <p>
          <strong>Right to Terminate:</strong> The Company reserves the right to suspend or terminate your access to InstaBlitz at any time for violation of these terms, failure to comply with local laws, or any behavior that is deemed harmful to the platform or its users.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          This Agreement shall be governed by and construed in accordance with the laws of the State of [State], without regard to its conflict of law provisions.
        </p>

        <h2>9. Changes to the Terms</h2>
        <p>
          The Company may amend or update these terms from time to time. You will be notified of any material changes, and your continued participation as an InstaBlitz driver will constitute your acceptance of the updated terms.
        </p>

        <h2>10. Contact Information</h2>
        <p>
          If you have any questions about these Terms and Conditions, please contact us at [Company Contact Information].
        </p>
      </section>
    </div>
  );
};

export default TcInstaBlitzDriver;
