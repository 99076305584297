import React, { useState } from 'react';
import './RegisterScreen.css';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // ADDED db import to write user data to Firestore
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // ADDED for creating user doc in Firestore
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // ADDED icons for password toggle

const RegisterScreen = () => {
  const [email, setEmail] = useState('');          // State for email
  const [password, setPassword] = useState('');    // State for password
  const [loading, setLoading] = useState(false);   // State for loading indicator
  const [showPassword, setShowPassword] = useState(false); // State for password visibility toggle

  const navigate = useNavigate();

  // Function to handle registration
  const handleRegister = async () => {
    if (!email || !password) {
      alert('Please enter both email and password.');
      return;
    }

    setLoading(true);
    try {
      // Create a user with email and password in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create a corresponding user document in Firestore
      // Storing only email for now; you can add more fields as needed
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
      });

      // Alert user and prompt them to login manually
      alert('Registration successful. Please log in to continue.');
      navigate('/login', { state: { from: 'register' } });
    } catch (error) {
      alert(`Registration Failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Toggle password visibility
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="register-container">
      {/* Logo */}
      <img src="/blitz_logo.png" alt="BLITZ Logo" className="logo" />
      
      {/* Heading */}
      <h1>Register</h1>
      
      {/* Email Input (Square styling handled in CSS) */}
      <input 
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        className="input-field square-input" // ADDED "square-input" class
      />
      
      {/* Password Input with toggle icon */}
      <div className="password-container">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field square-input" // ADDED "square-input" class
        />
        <button
          type="button"
          className="toggle-password-button"
          onClick={toggleShowPassword}
          aria-label={showPassword ? "Hide password" : "Show password"}
        >
          {showPassword ? <FaEyeSlash size={20} color="#8C1627" /> : <FaEye size={20} color="#8C1627" />}
        </button>
      </div>
      
      {/* Register Button (Square styling handled in CSS) */}
      <button 
        onClick={handleRegister} 
        className="register-button square-button" // ADDED "square-button" class
        disabled={loading}
      >
        {loading ? 'Registering...' : 'Register'}
      </button>
      
      {/* Footer Text */}
      <p className="footer-text">
        Already have an account?{' '}
        <span 
          className="login-link" 
          onClick={() => navigate('/login')}
        >
          Login
        </span>
      </p>
    </div>
  );
};

export default RegisterScreen;
