import React from 'react';
import './ToSPage.css'; // Optional: Add custom styling for your terms page

const ToSPage = () => {
  return (
    <div className="tos-container">
      <h1>Blitz Terms of Service</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>
      
      <section>
        <h2>Welcome to Blitz!</h2>
        <p>
          By accessing or using the Blitz platform, you agree to the following terms and conditions ("Terms of Service"). 
          If you do not agree to these terms, you may not access or use Blitz. 
          These terms apply to all users of the platform, including both riders and drivers.
        </p>
      </section>

      <section>
        <h2>1. Eligibility</h2>
        <p>
          To use Blitz, you must:
          <ul>
            <li>Be at least 18 years old for riders and 21 years old for drivers.</li>
            <li>Provide accurate and up-to-date personal information.</li>
            <li>Hold a valid driver’s license if you are registering as a driver.</li>
          </ul>
          InstaBlitz users must meet additional eligibility requirements as specified in the InstaBlitz Terms and Conditions.
        </p>
      </section>

      <section>
        <h2>2. Blitz Features</h2>
        <p>
          Blitz offers two core services:
          <ul>
            <li>Ridesharing: Standard ridesharing where users request rides via the app, similar to other ridesharing platforms.</li>
            <li>InstaBlitz: A spontaneous ride feature where drivers can offer rides instantly by scanning a rider’s QR code.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>3. User Responsibilities</h2>
        <p>
          <strong>Riders:</strong>
          <ul>
            <li>Provide accurate pickup and drop-off locations.</li>
            <li>Ensure that payment methods linked to your account are valid.</li>
          </ul>
          <strong>Drivers:</strong>
          <ul>
            <li>Maintain a roadworthy and insured vehicle.</li>
            <li>Ensure compliance with local laws and traffic regulations while using Blitz or InstaBlitz.</li>
            <li>Adhere to additional InstaBlitz Terms and Conditions for instant ride provision.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>4. Payments and Fees</h2>
        <p>
          <ul>
            <li>Riders agree to pay the fare as displayed within the app upon confirming a ride.</li>
            <li>Blitz reserves the right to modify fare structures at any time. Any changes will be communicated through the app.</li>
            <li>Drivers will receive payment via a verified debit card or other methods supported by the platform.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>5. Driver Insurance</h2>
        <p>
          All drivers must have valid personal auto insurance. InstaBlitz drivers must acknowledge that they are using personal insurance, 
          which may not cover all liabilities associated with providing rides. Blitz is not responsible for covering damages or liabilities during the ride.
        </p>
      </section>

      <section>
        <h2>6. Cancellation and Refunds</h2>
        <p>
          <ul>
            <li>Riders may cancel a ride request before the driver accepts the trip without any charge.</li>
            <li>Once a ride is accepted, cancellation fees may apply.</li>
            <li>Refunds are processed according to our refund policy, available in the app.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>7. Data Collection and Privacy</h2>
        <p>
          Blitz collects personal information, including location data, payment details, and ride history. 
          All data is handled according to our Privacy Policy, which is available [here].
        </p>
      </section>

      <section>
        <h2>8. Liability</h2>
        <p>
          <ul>
            <li>Blitz is not liable for any damages, personal injury, or loss incurred while using the platform.</li>
            <li>Drivers assume full responsibility for their safety and that of their passengers during rides.</li>
            <li>Blitz does not guarantee that your insurance will cover all liabilities.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>9. Account Suspension and Termination</h2>
        <p>
          Blitz reserves the right to suspend or terminate your account at any time for violations of these terms or any unlawful behavior.
        </p>
      </section>

      <section>
        <h2>10. InstaBlitz-Specific Terms</h2>
        <p>
          The InstaBlitz feature allows drivers to provide instant rides by scanning a QR code. 
          Participation in InstaBlitz requires compliance with additional driver eligibility criteria, 
          insurance requirements, and spontaneous ride limitations, as outlined in the InstaBlitz Terms and Conditions.
        </p>
      </section>

      <section>
        <h2>11. Changes to Terms</h2>
        <p>
          Blitz reserves the right to modify these Terms of Service at any time. 
          Any changes will be communicated via the app, and continued use of the service constitutes acceptance of the updated terms.
        </p>
      </section>

      <section>
        <h2>12. Governing Law</h2>
        <p>
          These Terms are governed by and construed in accordance with the laws of [State], without regard to its conflict of law principles.
        </p>
      </section>

      <section>
        <h2>13. Contact Information</h2>
        <p>
          If you have any questions about these Terms of Service, please contact us at [Company Contact Information].
        </p>
      </section>
    </div>
  );
};

export default ToSPage;
