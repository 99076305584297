import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { getFirestore, doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import './RideCompletionAndSummary.css';

const RideCompletionAndSummary = ({ rideId }) => {
  const [rideData, setRideData] = useState(null);
  const [pickupAddress, setPickupAddress] = useState('Loading...');
  const [dropoffAddress, setDropoffAddress] = useState('Loading...');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isAtDestination, setIsAtDestination] = useState(false);
  const [rideCompleted, setRideCompleted] = useState(false);
  const db = getFirestore();
  const googleApiKey = 'AIzaSyDVTRR4e9wo3V1dxI9SmlIYIoqAoELfhrc';
  const proximityThreshold = 50;

  // Fetch ride details from Firestore
  useEffect(() => {
    const fetchRideDetails = async () => {
      try {
        const rideRef = doc(db, 'fastTrackRides', rideId);
        const rideDoc = await getDoc(rideRef);

        if (rideDoc.exists()) {
          const data = rideDoc.data();

          // Ensure pickupLocation and dropoffLocation are valid before calling reverseGeocode
          if (data?.pickupLocation && data?.dropoffLocation) {
            reverseGeocode(data.pickupLocation, setPickupAddress);
            reverseGeocode(data.dropoffLocation, setDropoffAddress);
          } else {
            console.error('Invalid location data');
          }

          setRideData(data);
        } else {
          console.error('Ride document not found');
        }
      } catch (error) {
        console.error('Error fetching ride details:', error);
      }
    };

    fetchRideDetails();
  }, [rideId, db]);

  // Continuously monitor the driver's location
  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition((position) => {
      const { latitude, longitude } = position.coords;
      const driverLocation = { lat: latitude, lng: longitude };
      setCurrentLocation(driverLocation);

      if (rideData && isNearDestination(driverLocation, rideData.dropoffLocation)) {
        setIsAtDestination(true);
      }
    });

    return () => navigator.geolocation.clearWatch(watchId);
  }, [rideData]);

  // Calculate the distance between two points
  const calculateDistance = (point1, point2) => {
    if (!point1 || !point2) return 0; // Handle undefined values safely
    const R = 6371000; // Radius of Earth in meters
    const dLat = (point2.lat - point1.lat) * (Math.PI / 180);
    const dLng = (point2.lng - point1.lng) * (Math.PI / 180);
    const lat1 = point1.lat * (Math.PI / 180);
    const lat2 = point2.lat * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const isNearDestination = (driverLocation, destination) => {
    if (!driverLocation || !destination) return false; // Check if data exists
    const distance = calculateDistance(driverLocation, destination);
    return distance <= proximityThreshold;
  };

  // Reverse geocode function for addresses
  const reverseGeocode = async (location, setAddress) => {
    if (location && location.lat && location.lng) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${googleApiKey}`
        );
        const data = await response.json();
        const address = data.results[0]?.formatted_address || 'Unknown location';
        setAddress(address);
      } catch (error) {
        console.error('Error fetching address:', error);
        setAddress('Unknown location');
      }
    } else {
      console.error('Invalid location data');
      setAddress('Invalid location');
    }
  };

  const handleCompleteRide = async () => {
    try {
      const rideRef = doc(db, 'fastTrackRides', rideId);
      await updateDoc(rideRef, {
        status: 'completed',
        updatedAt: serverTimestamp(),
      });
      setRideCompleted(true);
    } catch (error) {
      console.error('Error marking ride complete:', error);
    }
  };

  // Render the completion and summary flow
  if (isAtDestination && !rideCompleted) {
    return (
      <div className="completion-container">
        <h2>You've arrived at the destination!</h2>
        <p>Please confirm the ride completion.</p>
        <button className="complete-button" onClick={handleCompleteRide}>
          Complete Ride
        </button>
      </div>
    );
  }

  if (rideCompleted) {
    return (
      <div className="summary-container">
        <h2>Trip Completed!</h2>
        {rideData && (
          <>
            <div className="summary-card">
              <div className="fare-row">
                <span>Total Fare:</span>
                <span>${rideData.fare ? rideData.fare.toFixed(2) : '0.00'}</span>
              </div>
              <div className="fare-row">
                <span>Your Earnings (80%):</span>
                <span>${(rideData.fare * 0.8).toFixed(2)}</span>
              </div>
              <div className="location">
                <strong>Pickup Location:</strong> {pickupAddress}
              </div>
              <div className="location">
                <strong>Dropoff Location:</strong> {dropoffAddress}
              </div>
            </div>
            <div className="join-prompt">
              <p>Enjoyed this? <strong>Join Blitz today!</strong></p>
              <button className="join-button">Complete Onboarding</button>
            </div>
          </>
        )}
      </div>
    );
  }

  // Default map view
  return (
    <LoadScript googleMapsApiKey={googleApiKey}>
      <div className="map-container">
        <GoogleMap
          center={currentLocation || { lat: 37.7749, lng: -122.4194 }} // Default to San Francisco
          zoom={14}
          mapContainerStyle={{ height: '100%', width: '100%' }}
        >
          {rideData && (
            <>
              <Marker position={rideData.pickupLocation} label="P" />
              <Marker position={rideData.dropoffLocation} label="D" />
            </>
          )}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default RideCompletionAndSummary;