// src/pages/DriverNavigationPage.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Ensure this path is correct
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import './DriverNavigationPage.css'; // Ensure this CSS file exists

// Helper function to calculate driver's cut
const calculateDriverCut = (fare) => {
  const commissionRate = 0.2; // 20% commission
  if (typeof fare !== 'number' || isNaN(fare)) {
    return '0.00';
  }
  return (fare * (1 - commissionRate)).toFixed(2);
};

// Main Component
const DriverNavigationPage = ({ driverEmail }) => {
  const [driver, setDriver] = useState(null);
  const [ride, setRide] = useState(null);
  const [passenger, setPassenger] = useState(null);
  const [loading, setLoading] = useState(false); // Start with false to display the form
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true; // Prevent state updates if component is unmounted

    const fetchDriverAndRide = async () => {
      if (!driverEmail) {
        if (isMounted) {
          setError('Driver email is not provided.');
        }
        return;
      }

      setLoading(true); // Start loading

      try {
        // Query to find the driver by email
        const driverQuery = query(
          collection(db, 'fastTrackDrivers'),
          where('email', '==', driverEmail)
        );
        const driverSnapshot = await getDocs(driverQuery);

        if (driverSnapshot.empty) {
          if (isMounted) {
            setError('Driver not found.');
            setLoading(false);
          }
          return;
        }

        const driverDoc = driverSnapshot.docs[0];
        const driverData = driverDoc.data();
        if (isMounted) setDriver(driverData);

        if (driverData.rideID) {
          // Fetch ride data
          const rideDocRef = doc(db, 'fastTrackRides', driverData.rideID);
          const rideDoc = await getDoc(rideDocRef);

          if (rideDoc.exists()) {
            const rideData = rideDoc.data();
            if (isMounted) setRide(rideData);

            // Fetch passenger data
            if (rideData.riderID) {
              const passengerDocRef = doc(db, 'riders', rideData.riderID);
              const passengerDoc = await getDoc(passengerDocRef);

              if (passengerDoc.exists()) {
                if (isMounted) setPassenger(passengerDoc.data());
              } else {
                if (isMounted) setPassenger({ name: 'Unknown Passenger' });
              }
            } else {
              if (isMounted) setPassenger({ name: 'Unknown Passenger' });
            }
          } else {
            if (isMounted) setError('Ride not found.');
          }
        } else {
          if (isMounted) setError('No active ride assigned.');
        }

        if (isMounted) setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        if (isMounted) {
          setError('Failed to fetch data.');
          setLoading(false);
        }
      }
    };

    fetchDriverAndRide();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [driverEmail]);

  // Function to generate navigation URL
  const generateNavigationURL = () => {
    if (!ride || !ride.dropoffLocation) {
      alert('Dropoff location is not available.');
      return;
    }

    const { latitude, longitude } = ride.dropoffLocation;

    if (
      typeof latitude !== 'number' ||
      typeof longitude !== 'number' ||
      isNaN(latitude) ||
      isNaN(longitude)
    ) {
      alert('Invalid dropoff location coordinates.');
      return;
    }

    // Waze URL Scheme
    const wazeURL = `waze://?ll=${latitude},${longitude}&navigate=yes`;

    // Google Maps URL
    const googleMapsURL = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`;

    // Attempt to open Waze first
    window.location.href = wazeURL;

    // Fallback to Google Maps after a short delay if Waze is not installed
    setTimeout(() => {
      window.open(googleMapsURL, '_blank');
    }, 2000);
  };

  // Function to mark ride as completed
  const completeRide = async () => {
    if (!driver || !ride) {
      alert('Ride details are not available.');
      return;
    }

    const confirmCompletion = window.confirm(
      'Are you sure you want to mark this ride as completed?'
    );
    if (!confirmCompletion) return;

    try {
      const rideRef = doc(db, 'fastTrackRides', driver.rideID);
      await updateDoc(rideRef, {
        rideStatus: 'Completed',
        completedTimestamp: serverTimestamp(),
      });

      // Optionally, update driver's current rideID to null
      const driverRef = doc(db, 'fastTrackDrivers', driverEmail);
      await updateDoc(driverRef, {
        rideID: null,
      });

      alert('Ride marked as completed.');
      // Optionally, refresh the page or navigate to a different screen
      window.location.reload();
    } catch (err) {
      console.error('Error completing ride:', err);
      alert('Failed to complete the ride. Please try again.');
    }
  };

  // Destructure necessary fields with default values to prevent undefined errors
  const {
    pickupLocation = { address: 'N/A' },
    dropoffLocation = { address: 'N/A' },
    fare = 0,
    rideStatus = 'N/A',
    rideTimestamp = null,
  } = ride || {};

  const passengerName = passenger ? passenger.name : 'N/A';

  return (
    <div className="driver-navigation-container">
      {/* Blitz Logo */}
      <img src="/blitz_logo.png" alt="Blitz Logo" className="blitz-logo" />

      <h2>Ride Details</h2>

      {/* Display Error and Loading Messages within the UI */}
      {error && <div className="error-message">Error: {error}</div>}
      {loading && <div className="loading-message">Loading...</div>}

      {/* Main Content */}
      <div className="main-content">
        {/* Pickup and Dropoff Section */}
        <div className="pickup-dropoff-container">
          <div className="location-row">
            <div className="location-icon">
              <div className="circle"></div>
              <div className="rhombus"></div>
            </div>
            <div className="location-details">
              <p>
                <span>Pickup:</span> {pickupLocation.address}
              </p>
              <p>
                <span>Dropoff:</span> {dropoffLocation.address}
              </p>
            </div>
          </div>
        </div>

        {/* Ride Details Section */}
        <div className="ride-details">
          <p>
            <span>Passenger:</span> {passengerName}
          </p>
          <p>
            <span>Fare:</span> {fare !== undefined ? `$${fare.toFixed(2)}` : 'N/A'}
          </p>
          <p>
            <span>Your Cut:</span>{' '}
            {fare !== undefined ? `$${calculateDriverCut(fare)}` : 'N/A'}
          </p>
          <p>
            <span>Ride Status:</span> {rideStatus}
          </p>
          <p>
            <span>Ride Requested At:</span>{' '}
            {rideTimestamp
              ? new Date(rideTimestamp.seconds * 1000).toLocaleString()
              : 'N/A'}
          </p>
        </div>

        {/* Buttons */}
        <div className="button-container">
          <button
            onClick={generateNavigationURL}
            className="navigate-button"
            disabled={!ride || !ride.dropoffLocation}
          >
            Navigate to Dropoff
          </button>
          <button
            onClick={completeRide}
            className="complete-ride-button"
            disabled={!ride}
          >
            Complete Ride
          </button>
        </div>
      </div>
    </div>
  );
};

export default DriverNavigationPage;
